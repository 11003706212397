<template>
  <footer class="ps-footer ps-footer--1">
    <div style="padding: 1rem; background-color: #fff; color: #202453">
      <div class="ps-footer__middle">
        <div class="row">
          <div class="col-12 col-md-5">
            <div class="row">
              <div class="col-12 col-md-10 py-3" style="">
                <div class="ps-footer--address">
                  <p style="font-size: 1.5rem;">
                    <b> <span style="color: #202453;"> E</span> -Learning </b>
                  </p>
                  <p style="font-size: 0.8rem;">
                    เว็บไซต์ e-learning ที่มีคุณภาพ ครบด้วยหลักสูตรมากมาย
                    ระดับสากล พร้อมระบบการเรียนการสอนที่สามารถวัดผลได้
                  </p>

                  <div>
                    <a
                      class="ps-social__link facebook px-2"
                      href=""
                      target="_blank"
                      ><font-awesome-icon
                        :icon="{ prefix: 'fab', iconName: 'facebook' }"
                        size="2x"
                    /></a>

                    <a class="ps-social__link line px-2" href="" target="_blank"
                      ><font-awesome-icon
                        :icon="{ prefix: 'fab', iconName: 'line' }"
                        size="2x"
                      />
                    </a>
                    <a class="ps-social__link line px-2" href="" target="_blank"
                      ><font-awesome-icon
                        :icon="{ prefix: 'fab', iconName: 'twitter' }"
                        size="2x"
                      />
                    </a>
                    <a class="ps-social__link line px-2" href="" target="_blank"
                      ><font-awesome-icon
                        :icon="{ prefix: 'fab', iconName: 'youtube' }"
                        size="2x"
                      />
                    </a>
                  </div>

                  <!-- <ul class="ps-social">
                    <li>
                      <a
                        class="ps-social__link facebook"
                        href=""
                        target="_blank"
                        ><i class="fa fa-facebook"> </i
                        ><span style="font-size: 0.8rem;" class="ps-tooltip">Facebook</span></a
                      >
                    </li>

                    <li>
                      <a class="ps-social__link line" href="" target="_blank"
                        ><font-awesome-icon
                          :icon="{ prefix: 'fab', iconName: 'line' }"
                        />
                        <span style="font-size: 0.8rem;" class="ps-tooltip">Line</span></a
                      >
                    </li>

                  <li>
                      <a
                        class="ps-social__link facebook-messenger"
                        href="https://page.facebook-messenger.me/?accountId=cobenhavn"
                        target="_blank"
                        ><small
                          ><font-awesome-icon
                            :icon="{
                              prefix: 'fab',
                              iconName: 'facebook-messenger',
                            }"
                        /></small>
                        <span class="ps-tooltip">Facebook Messenger</span></a
                      >
                    </li> -->
                  <!-- </ul> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div class="row">
              <div class="col-6 col-md-4">
                <div class="ps-footer--block">
                  <h5 style="font-size: 1rem;" class="pb-4">หน่วยงานที่เกี่ยวข้อง</h5>
                  <div style="font-size: 0.8rem;">หน่วยงานที่ 1</div>
                  <div style="font-size: 0.8rem;">หน่วยงานที่ 2</div>
                  <div style="font-size: 0.8rem;">หน่วยงานที่ 3</div>
                  <div style="font-size: 0.8rem;">หน่วยงานที่ 4</div>
                  <div style="font-size: 0.8rem;">หน่วยงานที่ 5</div>
                </div>
              </div>
              <div class="col-6 col-md-4">
                <div class="ps-footer--block">
                  <h5 class="pb-4">Quicklinks</h5>
                  <div style="font-size: 0.8rem;">หน้าหลัก</div>
                  <div style="font-size: 0.8rem;">หลักสูตรของฉัน</div>
                  <div style="font-size: 0.8rem;">ตั้งค่า</div>
                  <div style="font-size: 0.8rem;">แจ้งปัญหา</div>
                  <div style="font-size: 0.8rem;">คำถามที่พบบ่อย</div>
                </div>
              </div>
              <div class="col-6 col-md-4">
                <div class="ps-footer--block">
                  <h5 class="pb-4">ติดต่อ</h5>
                  <div style="font-size: 0.8rem;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="padding: 1.1rem; background-color: #fcc822"></div> -->
  </footer>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faLine,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
library.add([faFacebook, faLine, faTwitter, faYoutube]);
export default {
  computed: {},
};
</script>
