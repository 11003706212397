import axios from "axios";
import { User } from "./User";

export class Auth extends User {
    static get entity() {
        return "auth"
    }

    static setAuthId(authId) {
        localStorage.setItem("authId", authId);
    }

    static getAuthId() {
        return localStorage.getItem("authId");
    }

    static setAuthToken(authToken) {
        localStorage.setItem("authToken", authToken);
    }

    static getAuthToken() {
        return localStorage.getItem("authToken");
    }

    static async load() {
        await this.api().find(this.getAuthId());
    }

    static user() {
        return this.find(this.getAuthId());
    }

    static signOut() {
        localStorage.removeItem("authId");
        localStorage.removeItem("authToken");
    }

    static get apiConfig() {
        return {
            actions: {
                async find(id) {
                    return this.get(`/user_profiles/${id}`);
                },

                async getOTP(phone) {
                    let promise;

                    promise = await axios.post(
                        `${process.env.VUE_APP_OTP_ENDPOINT}/api/otp-send`, {
                            project_key: process.env.VUE_APP_OTP_PROJECT_KEY,
                            phone
                        }, {
                            headers: {
                                'api_key': process.env.VUE_APP_OTP_API_KEY__SEND,
                                'secret_key': process.env.VUE_APP_OTP_SECRET_KEY__SEND
                            }
                        }
                    );

                    if (!promise || !promise.data || !promise.data.result) {
                        const { code } = promise.data;

                        let message = "ไม่สามารถส่งคำร้องขอรหัส OTP ได้ กรุณาติดต่อผู้ดูแลระบบ";

                        if (code === "107") {
                            message = "เบอร์โทรศัพท์ไม่ถูกต้อง กรุณาตรวจสอบเบอร์โทรศัพท์";
                        }

                        throw message;
                    }

                    const { token } = promise.data.result;

                    return token;
                },

                async validateOTP(token, otp_code) {
                    let promise;

                    promise = await axios.post(
                        `${process.env.VUE_APP_OTP_ENDPOINT}/api/otp-validate`, {
                            token,
                            otp_code
                        }, {
                            headers: {
                                'api_key': process.env.VUE_APP_OTP_API_KEY__VALIDATE,
                                'secret_key': process.env.VUE_APP_OTP_SECRET_KEY__VALIDATE
                            }
                        }
                    );

                    if (!promise || !promise.data || !promise.data.result) {
                        const { code } = promise.data;

                        let message = "การตรวจสอบรหัส OTP ล้มเหลว กรุณาติดต่อผู้ดูแลระบบ";

                        if (code === "1006") {
                            message = "รูปแบบรหัส OTP ไม่ถูกต้อง กรุณาตรวจสอบรหัส OTP อีกครั้ง";
                        }

                        throw message;
                    }

                    const { status } = promise.data.result;

                    if (!status) {
                        throw "รหัส OTP ไม่ถูกต้อง กรุณาตรวจสอบรหัส OTP อีกครั้ง";
                    }

                    return status;
                },

                async signIn(loginForm) {
                    let promise;

                    promise = await this.post('/users/login', loginForm, { save: false });

                    const { response_status, message, data } = promise.response.data;

                    if (response_status === "ERROR") {
                        throw message;
                    }

                    const { user, token } = data;

                    return {
                        user,
                        token
                    };

                    // if (!result.response) return;
                    // const { data: { response_status = "" } } = result.response;
                    // if (response_status == "ERROR") return result;
                    // const { data: { user, token } } = result.response.data;
                    // result.response.data.data = { ...user, token }
                    // await result.save()
                },

                async register(form) {
                    let result = await this.post('/users/', {
                        ...form
                    }, { save: false });
                    if (!result.response) return;
                    // const { data: userResponse } = result.response.data;
                    // result.response.data.data.id = userResponse.user.id;
                    await result.save()
                    return result;
                },
            }
        }
    }
}