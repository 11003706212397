<template>
<div style="    
    border-radius: 20px;
    border: 1px solid #BA3538;
    height: 30px;
    width: 140px;
    background: #BA3538;">
  <a class="pl-3" href="javascript:void(0);" @click="signOut" style="color: #fff; ">
    <font-awesome-icon icon="sign-out-alt" class="opacity-5" />
    ออกจากระบบ
  </a>
</div>
</template>

<script>
import { Auth } from "../../models";
export default {
  methods: {
    signOut() {
      Auth.signOut();

      this.$toast.info("ออกจากระบบเรียบร้อย");

      setTimeout(() => {
        location.href = "/sign-in";
      }, 1000);
    },
  },
};
</script>
